.received {
    padding: 140px 50px;
}

.received h1 {
    color: #265b9e;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    padding: 10px;
    font-size: 50px;
}

.received p {
    text-align: center;
    font-size: 20px;
}

.received a {
    text-decoration: none;
}

.received button {
    text-transform: uppercase;
    border-radius: 25px;
    padding: 15px 40px;
    border: 0px;
    display: block;
    margin: 50px auto 0 auto;
    transition: all 1s ease;
    font-size: 20px;
}

.received button:hover {
    background-color: #C0C0C0;
}