.contact {
    overflow: hidden;
}

.contact .headers {
    margin: 0 5%;
}

.contact .contact_title {
    color: #265b9e;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    margin-top: 50px;
    padding: 10px;
}

.contact .contactHeader {
    max-width: 1200px;
    margin: auto;
    text-align: center;
    font-size: 20px;
}

.formContainer {
    margin: 30px auto;
    display: flex;
    flex-direction: row;
    width: 90%;
    max-width: 1200px;
    box-shadow: 5px 5px 10px #c9c9c9;
    border-radius: 10px;
}

.formContainer div {
    width: 50%;
}

@media all and (max-width: 800px) {
    .formContainer {
        flex-direction: column;
    }

    .formContainer div {
        width: 100%;
    }
}

.formContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.actualForm {
    padding: 50px;
}

.contact form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 800px;
    margin: auto;
}

.contact label {
    margin-top: 20px;
    font-size: 25px;
}

.contact input {
    padding: 10px;
    border: solid 1px #e4e4e4;
    border-radius: 5px;
    box-shadow: 2px 5px 5px #f8f8f8;
}

.contact textarea {
    height: 200px;
    padding: 10px;
    border: solid 1px #cfcfcf;
    border-radius: 5px;
    box-shadow: 2px 5px 5px #f8f8f8;
}

.contact input[type=submit] {
    margin-bottom: 40px;
    background-color: #265b9e;
    color: white;
    transition: all 1s ease;
    width: 100%;
    max-width: 150px;
    margin: 20px auto;
    border: 0px;
    box-shadow: 0 5px 10px 0 rgba(113, 149, 196, 0.5);
}

.contact input[type=submit]:hover {
    background-color: #164480;
    color: rgb(219, 206, 206);
}