@media all and (min-width: 913px) {
    nav {
        top: 0;
        position: fixed;
        display: flex;
        width: 100%;
        height: 10vh;
        background-color: white;
        color: white;
        overflow: hidden;
        flex-direction: row;
        z-index: 10;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }
}

.navLeft {
    display: flex;
    align-items: center;
}

.navLeft a {
    text-decoration: none;
}

.navLeft h1 {
    padding-top: 0.3em;
    font-weight: 700;
}

.navLeft img {
    width: 6.5vh;
    margin-right: 10px;
}

.navRight ul {
    display: flex;
    margin: 0;
    padding-left: 0;
}

.signin {
    border: none;
    color: black;
    padding: 8px 25px 5px 25px;
    margin: 0 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    border-radius: 20px;
    transition: all 1s ease;
}

.signin:hover {
    cursor: pointer;
    background-color: #c0c0c0;
}

/* mobile nav formatting begins */
.burger {
    /* mobile nav hidden when page is full size */
    display: none;
}

@media all and (max-width: 912px) {
    .mobile {
        /* the mobile class does not contain the tabs */
        top: 0;
        position: fixed;
        display: flex;
        width: 100%;
        height: 10vh;
        background-color: white;
        color: white;
        flex-direction: row;
        z-index: 10;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }

    /* menu burger begins */
    .burger {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 30px;
        height: 21px;
        cursor: pointer;
        transition: all 0.3s;
        overflow-y: hidden;
        margin-right: 10px;
    }

    .burger .bar {
        height: 3px;
        background-color: rgb(44, 44, 44);
        transition: transform 0.5s ease;
    }

    .line1 {
        width: 70%;
    }

    .line2 {
        width: 100%;
    }

    .line3 {
        width: 100%;
    }

    /* open burger menu begins */
    .toggle {
        width: 30px;
        height: 30px;
        justify-content: center;
    }

    .toggle .line1 {
        transform: rotate(-225deg) translate(4px, -3px);
        width: 100%;
    }

    .toggle .line2 {
        opacity: 0;
    }

    .toggle .line3 {
        transform: rotate(-135deg) translate(2px, 0px);
    }

    /* open burger menu ends */
    /* burger menu ends */

    .navRight {
        /* sets tabs as initially hidden */
        display: none;
        position: fixed;
        background-color: white;
        margin-top: 10vh;
        z-index: 20;
    }

    .active {
        /* shows tabs when burger menu clicked */
        display: flex;
    }

    /* mobile tabs formatting begins */
    .navRight {
        width: 100%;
        height: 100vh;
    }

    .navRight ul {
        width: 100%;
        height: 90vh;
        flex-direction: column;
        padding-bottom: 50px;
        justify-content: center;
    }

    .navRight ul li {
        text-align: center;
        padding: 15px;
        animation: navLinkFade 1s;
    }

    .navRight ul li a {
        margin: .5rem 1rem;
    }

    .navRight .active {
        display: flex;
    }

    @keyframes navLinkFade {
        from {
            opacity: 0;
            transform: translateY(50px);
        }

        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    /* mobile tabs formatting ends */
}

/* mobile nav formatting ends */

/* scrollbar */

::-webkit-scrollbar {
    width: 12px;
      background-color: #F5F5F5;
 }
 
  ::-webkit-scrollbar-track {
      background-color: #ffffff;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
 }
 
  ::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background: #6a9bdb;
 }
 
  ::-webkit-scrollbar-thumb:hover {
   background: #5a89c7;
 }